<template>
  <div class="chart">
    <div class="chart-title">
      <h1 v-text="$t(name)" class="text-subtitle"></h1>
      <div class="filters">
        <multiselect
          v-if="filterSeries"
          v-model="filterSelection"
          v-bind:options="filterOptions"
          v-bind:searchable="true"
          v-bind:close-on-select="false"
          v-bind:show-labels="false"
          v-bind:multiple="true"
          v-bind:placeholder="filterPlaceholder"
          v-bind:limit-text="filterLimit"
          v-bind:limit="1">
          <template slot="noResult">{{ $t('multiselect.no-result') }}</template>
          <template slot="noOptions">{{ $t('multiselect.no-options') }}</template>
        </multiselect>
        <input-date
          ref="inputDate"
          v-bind:startDate="currentStartDate"
          v-bind:endDate="currentEndDate"
          v-bind:options="{ opens: 'left' }"
          v-on:update="rangeChanged"/>
        <download-options
          v-on:export-chart="exportChartAs"/>
      </div>
    </div>
    <div class="chart-content" v-resize="onResize">
      <div class="chart-pagination">
        <span
          class="nav-previous"
          v-if="paginationEnabled"
          v-bind:class="{ 'disabled': !previousEnabled }"
          v-on:click="previousPage">
          <chevron-left-icon/>
        </span>
        <div
          class="chart-wrapper"
          v-bind:class="{ 'paginated': paginationEnabled}">
          <chart-component
            ref="chartComponent"
            v-bind:null-to-zeroes="nullToZeroes"
            v-bind:translatable="translatable"
            v-bind:time-format="timeFormat"
            v-bind:chart-id="chartId"
            v-bind:date-format="dateFormat"
            v-bind:date-y-format="dateYFormat"
            v-bind:labels="chartLabels"
            v-bind:series="chartSeries"
            v-bind:type="type"
            v-bind:type-loader="typeLoader"/>
        </div>
        <span
          class="nav-next"
          v-if="paginationEnabled"
          v-bind:class="{ 'disabled': !nextEnabled }"
          v-on:click="nextPage">
          <chevron-right-icon/>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import resize from 'vue-resize-directive';

export default {
  components: {
    ChartComponent: () => import('@/components/ChartComponent.vue'),
    InputDate: () => import('@/components/InputDate.vue'),
    Multiselect: () => import('vue-multiselect'),
    DownloadOptions: () => import('@/components/DownloadOptions.vue'),
  },
  directives: {
    resize,
  },
  props: {
    nullToZeroes: {
      type: Boolean,
      default: false,
    },
    translatable: {
      type: Boolean,
      default: false,
    },
    timeFormat: {
      type: Boolean,
      default: false,
    },
    paginate: {
      type: Boolean,
      default: false,
    },
    perPage: {
      type: Number,
      default: 7,
    },
    filterSeries: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    name: {
      required: true,
      type: String,
    },
    chartId: {
      required: true,
      type: String,
    },
    chartData: {
      required: true,
    },
    type: {
      required: true,
      type: String,
      validator(value) {
        return ['bar', 'column', 'stackedColumn', 'line', 'pie', 'heatmap'].indexOf(value) !== -1;
      },
    },
    typeLoader: {
      required: false,
      type: String,
      default: 'chart',
    },
  },
  data() {
    return {
      currentPage: 0,
      currentStartDate: null,
      currentEndDate: null,
      dateFormat: null,
      dateYFormat: null,
      labels: [],
      series: [],
      filterSelection: [],
    };
  },
  computed: {
    paginationEnabled() {
      return this.paginate && this.totalPages > 1;
    },
    previousEnabled() {
      return this.currentPage > 0;
    },
    nextEnabled() {
      return this.currentPage < this.totalPages - 1;
    },
    totalElements() {
      return this.labels.length;
    },
    totalPages() {
      return Math.ceil(this.totalElements / this.perPage);
    },
    startElement() {
      return this.currentPage * this.perPage;
    },
    endElement() {
      return this.startElement + this.perPage;
    },
    chartLabels() {
      if (this.paginate) {
        return this.paginatedLabels();
      }

      return this.labels;
    },
    chartSeries() {
      if (this.paginate) {
        return this.paginatedSeries();
      }

      if (this.filterSeries) {
        return this.filteredSeries;
      }

      return this.series;
    },
    filteredSeries() {
      if (this.filterSelection.length > 0) {
        return this.series.filter(el => this.filterSelection.includes(el.name));
      }

      return this.series;
    },
    filterOptions() {
      return this.series.map(el => el.name)
        .sort();
    },
    filterPlaceholder() {
      return this.placeholder !== '' ? this.placeholder : this.$t('multiselect.placeholder');
    },
  },
  watch: {
    chartData(newValue) {
      if (newValue) {
        this.dateFormat = newValue.dateFormat ?? null;
        this.dateYFormat = newValue.dateYFormat ?? null;
        this.labels = newValue.categories ?? [];
        this.series = newValue.series;
      }
    },
  },
  methods: {
    previousPage() {
      if (this.previousEnabled) {
        this.currentPage -= 1;
      }
    },
    nextPage() {
      if (this.nextEnabled) {
        this.currentPage = this.currentPage + 1;
      }
    },
    paginatedLabels() {
      return this.labels.slice(this.startElement, this.endElement);
    },
    paginatedSeries() {
      return this.series.map(el => ({
        ...el,
        data: el.data.slice(this.startElement, this.endElement),
      }));
    },
    rangeChanged(startDate, endDate) {
      this.currentStartDate = startDate;
      this.currentEndDate = endDate;
      this.currentPage = 0;
      this.filterSelection = [];
      this.$emit('change', this.chartId, startDate, endDate);
    },
    resetFilters() {
      this.currentPage = 0;
      this.filterSelection = [];

      if (this.$refs.inputDate) {
        this.$refs.inputDate.reset();
      }
    },
    onResize() {
      if (this.$refs.chartComponent) {
        this.$refs.chartComponent.triggerResize();
      }
    },
    filterLimit(count) {
      return `${count} ${this.$t('multiselect.limit-suffix')}`;
    },
    exportChartAs(mime) {
      this.$refs.chartComponent.exportAs(this.chartId, mime);
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/_data-chart.scss';
</style>
